// => 订单管理
import http from "./http"
// 修改商家订单状态
export const $apiSetSellerOrderStatus = (data) => http({ method: "post", url: "/user/order/sellState", params: data });
// 修改买家订单状态
export const $apiSetBuyerOrderStatus = (data) => http({ method: "post", url: "/user/order/state", params: data });
// 确认订单货款
export const $apiConfirmOrderRevise = (data) => http({ method: "get", url: "/user/order/revise", params: data });
// 确认线下付款
export const $apiConfirmOfflinePay = (data) => http({ method: "post", url: "/Goods/Web/orderOffline", params: data });

/** @name { 询价列表 } */
export const $apiInquiryList = (data) => http({ method: "get", url: "/Goods/reveal/inquiry", params: data });


/** @name { 订单红包 } */
export const $apiSendRedEnvelope = (data) => http({ method: "get", url: "/user/red/envelope", params: data });


// @ 个人中心 - 我的订单 - 修改价格
export const $apiEditOrderPrice = (data) => http({
  method: "post",
  url: "/Goods/Web/orderOffline",
  params: data,
});

// @ 个人中心 - 我的订单 - 确认线下付款
export const $apiConfirmOfflinePayment = (data) => http({
  method: "post",
  url: "/Goods/Web/orderOffline",
  params: data,
});


// @ 个人中心 - 我的订单 - 卖家修改状态 {token type orderId}
export const $apiChangeSellerOrderState = (data) =>http({
  method: "post",
  url: "/user/order/sellState",
  params: data,
});

// @ 个人中心 - 我的订单 - 买家修改状态  {token type orderId}
export const $apiChangeBuyerOrderState = (data) =>http({
  method: "post",
  url: "/user/order/state",
  params: data,
});
